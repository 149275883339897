<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
    </lz-dialog>
    <design ref="design"></design>
    <lz-dialog
      title="在线预览"
      :width="500"
      :is-close-btn="false"
      ref="lzProductDialog">
      <div data-flex="main:center" style="height: 568px">
        <iframe scrolling="yes" frameborder="0" :src="templateUrl" style="height: 100%;width: 320px"></iframe>
      </div>
    </lz-dialog>
    <order-view ref="orderView"></order-view>
  </div>
</template>
<script>
import cfg from '@/config';
import design from './design';
import orderView from './orderVIew';

export default {
  components: {
    design,
    orderView,
  },
  data() {
    return {
      templateUrl: '',
      search: [
        { name: '模版名称', type: 'text', fieldName: 'keyword', value: '' },
      ],
      config: {
        url: '/template/list',
        setTableFormat: (list) => {
          list.forEach((el) => {
            if (el.btn_copy) {
              el.htmlButton.unshift({
                name: '复制模版',
                key: 'copy',
                type: 'warning',
              });
            }
            el.htmlButton.unshift({
              name: '订单详情',
              key: 'order-view',
              type: 'primary',
            });
            el.htmlButton.unshift({
              name: '预览',
              key: 'preview',
              type: 'success',
            });
            if (el.btn_template_edit) {
              el.htmlButton.unshift({
                name: '编辑模版',
                key: 'template',
                type: 'success',
              });
            }
          });
          return list;
        },
      },
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '名称', prop: 'name' },
        { name: '模版编号', prop: 'code' },
        { name: '图片', prop: 'pic', type: 'image' },
        {
          name: '是否显示',
          func: (ops) => (ops.status ? '否' : '是'),
        },
        { name: '创建时间', prop: 'create_time' },
        { name: '操作', prop: 'htmlButton' },
      ],
      form: [
        { fieldName: 'name', type: 'text', name: '名称', value: '', required: true },
        { fieldName: 'code', type: 'text', name: '模版编号', value: '', required: true },
        {
          fieldName: 'status',
          type: 'select',
          name: '状态',
          required: true,
          value: '',
          options: [
            { label: '启用', value: 0 },
            { label: '禁用', value: 1 },
          ],
        },
        { fieldName: 'sort', type: 'number', name: '排序', value: '', required: false },
        { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
        {
          fieldName: 'attachment_id',
          type: 'upload',
          name: '图片',
          value: [],
          multiple: false,
          required: false,
          width: '100%',
        },
        { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
    };
  },
  methods: {
    async handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/template/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        case 'template': {
          const params = {
            id: element.id,
          };
          const response = await this.$baseHttp.get('/template/detail', { params });
          if (!response) return;
          this.$refs.design.init(response.data);
        }
          break;
        case 'copy': {
          const params = {
            id: element.id,
          };
          const response = await this.$baseHttp.get('/template/detail', { params });
          if (!response) return;
          this.$set(response.data, 'id', '');
          this.handleDialogForm(response.data);
        }
          break;
        case 'preview':
          this.$refs.lzProductDialog.dialogVisible = true;
          this.templateUrl = `${cfg.giftUrl}/h5?template_id=${element.id}`;
          break;
        case 'order-view':
          this.$refs.orderView.init(element.id);
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          const response = await this.$baseHttp.post('/template/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      if (response && response.pic) {
        this.$set(response, 'attachment_id', [{
          id: response.attachment_id,
          pic: response.pic,
          file: response.pic,
          ext: 'jpg',
        }]);
      }
      this.$nextTick(() => {
        this.$refs.lzForm.setValue({
          data: response || {},
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
