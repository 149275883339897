import { render, staticRenderFns } from "./orderVIew.vue?vue&type=template&id=bb2f3530&"
import script from "./orderVIew.vue?vue&type=script&lang=js&"
export * from "./orderVIew.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports