<template>
  <lz-dialog
    title="订单详情"
    :is-close-btn="false"
    ref="lzDialog">
    <lz-list
      :config="config"
      :table="table"
      :is-router="false"
      :search-btn="[]"
      :search="search"
      ref="lzList">
    </lz-list>
  </lz-dialog>
</template>
<script>
export default {
  data() {
    return {
      search: [
        { name: '订单id', fieldName: 'template_id', value: '' },
      ],
      table: [
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '客户名称', prop: 'company_name' },
        { name: '订单类型', prop: 'type_name' },
        { name: '页面标题', prop: 'webtitle' },
        { name: '年份', prop: 'year' },
        { name: '场景', prop: 'scene_name' },
        { name: '用途', prop: 'purpose_name' },
        { name: '价位', prop: 'price_title_name' },
        { name: '创建时间', prop: 'create_time' },
      ],
      config: {
        url: '/ticket/list',
      },
    };
  },
  methods: {
    init(id) {
      this.search[0].value = id;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.lzList.getData(true);
      });
    },
  },
};
</script>
